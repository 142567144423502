import './App.css';
import Navbar from './components/navbar/Navbar';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Aboutme from './containers/aboutme/Aboutme';
import Resume from './containers/resume/Resume';
import Project from './containers/project/Project';
import Footer from './containers/footer/Footer';
import Contact from './containers/contact/Contact';
function App() {
  return (
    <div className="App">
     <Router> 
        <div className='navbar'>
           <Navbar />
        </div>
       <Routes>
        <Route path='/' exact element={<Aboutme />} />
        <Route path='/resume' exact element={<Resume />} />   
        <Route path='/project' exact element={ <Project/>} />
         <Route path='/contact' exact element={ <Contact/>} />
       </Routes>
     </Router>
      <Footer/>
    </div>
  );
}

export default App;
