import React from 'react'
import './contact.css';
import {MdOutlineMail} from 'react-icons/md';
import {RiMessengerLine} from 'react-icons/ri';
import {BsWhatsapp} from 'react-icons/bs';
import  { useRef } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
   const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
 // create new account for emailjs 
    emailjs.sendForm('', '', form.current, '')
    e.target.reset()
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='p-12 bg-[#E6DACE]'>
    <h1 className='text-center text-lg lg:text-xl 2xl:text-2xl mb-4 font-medium mt-[100px]'>Let's talk</h1>
      <div className='container contact__container section__padding'>
         <div className='contact__options lg:ml-12'>
           <article className='contact__option'>
             <MdOutlineMail className='contact__option-icon' />
             <h4 className='lg:text-xl md:text-lg text-base font-medium mb-2'>Email</h4>
             <h5>weldemeskelyared@gmail.com</h5>
             <a href="mailto:weldemeskelyared@gmail.com" target="_blank" rel="noreferrer" >Send a message</a>
           </article>
           <article className='contact__option'>
             <RiMessengerLine className='contact__option-icon' />
             <h4 className='lg:text-xl md:text-lg text-base font-medium mb-2'>Messenger</h4>
             <h5>Yared Taddese</h5>
             <a href="https://m.me/melaku.ftwi" target="_blank" rel="noreferrer" >Send a message</a>
           </article>
           <article className='contact__option'>
             <BsWhatsapp className='contact__option-icon' />
             <h4 className='lg:text-xl md:text-lg text-base font-medium mb-2'>WhatsApp</h4>
             <h5>+251 939346647</h5>
             <a href="https://api.whatsapp.com/send?phone=+251939346647" target="_blank" rel="noreferrer" >Send a message</a>
           </article>
         </div>
         <form ref={form} onSubmit={sendEmail}>
           <input type="text" name="name" placeholder="Your Full Name" required />
           <input type="email" name="email" placeholder="Your Email" required />
           <textarea name="message" rows="7" placeholder="Your Message" required ></textarea>
           <button type='submit' className=' md:w-[150px] w-[100px] h-[32px] md:h-[40px] text-xs md:text-base hover:bg-transparent hover:text-black text-white border-cyan-900 border-2 rounded-full p-2 bg-[#0050FF]'>Send Message</button>
         </form>
      </div>
    </div>
  )
}

export default Contact