import React from 'react'
import resume from "../../assets/YaredTaddesResume.pdf";

function Resume() {
  return (
    <div className='p-12 bg-[#E6DACE] overflow-x-hidden '>
      <h1 className='text-center text-xl  md:text-3xl font-bold mt-[100px]'>Resume</h1>
      <div className='flex justify-center items-center w-screen gap-12 md:gap-[450px] mt-7 2xl:mt-12'>
        <h1 className='-ml-[90px] font-medium text-lg md:text-xl'>Experience</h1>
          <a target="_blank" rel="noreferrer" href={resume} download>
            <button className=' md:w-[150px] md:h-[40px] text-xs md:text-base hover:bg-transparent hover:text-black text-white border-cyan-900 border-2 rounded-full p-2 bg-[#0050FF]'>DOWNLOAD CV</button>
          </a>
      </div>
      <div className='flex justify-center mt-8'>
        <div className='container border-box md:w-[700px] md:h-[370px] border shadow-lg p-10 rounded-lg bg-[#F5F5F5]'>
          <h1 className='text-blue-600 text-sm md:text-xl md:font-normal'>July 2020 - September 2023</h1>
          <div className='md:grid md:grid-cols-2 grid mt-4 '>
            <div className='space-y-2'>
             <h3 className='md:font-normal md:text-base text-xs'>Full Stack Developer and <br /> DevOps Engineer</h3>
             <p  className='font-light text-xs md:text-sm'>Remote US Company</p>
             <p  className='font-light text-xs md:text-sm'>Las Vegas, NV, USA</p>
            </div>
            <div>
              <p className='text-justify text-sm text-gray-800 md:text-base md:mt-0 mt-3'>Implement new features in an already developed complex huge game system (very challenging in design and implementation)
                 Manage and maintain deployment scripts and details for more than 10 services with different technologies
                 Fix new bugs and issues on all frontend, backend, and database sides.</p>
            </div>
          </div>
        </div>
        </div> 

        <div className='flex justify-center mt-8'>
        <div className='container border-box md:w-[700px] md:h-[370px] border shadow-lg p-10 rounded-lg bg-[#F5F5F5]'>
          <h1 className='text-blue-600 text-sm md:text-xl md:font-normal'>March 2017 - June 2020</h1>
          <div className='md:grid md:grid-cols-2 grid mt-4 '>
            <div className='space-y-2'>
             <h3 className='md:font-normal md:text-base text-xs'>System Architect and<br /> Developer</h3>
             <p  className='font-light text-xs md:text-sm'>iCog Labs</p>
             <p  className='font-light text-xs md:text-sm'>Addis Ababa, Ethiopia</p>
            </div>
            <div>
              <p className='text-justify font-light text-gray-800 text-sm md:text-base md:mt-0 mt-3 '>Discuss decentralized system design from an implementation perspective
                Suggest and optimize system design and architecture from an implementation perspective
                Implementation of core backends Design UX/UI discussing with stakeholders
                Implement designed UX/UI.</p>
            </div>
          </div>
        </div>
        </div>

        <div className='flex justify-center mt-8'>
        <div className='container border-box md:w-[700px] md:h-[370px] border shadow-lg p-10 rounded-lg bg-[#F5F5F5]'>
          <h1 className='text-blue-600 text-sm md:text-xl md:font-normal'>February 2015 - November 2018</h1>
          <div className='md:grid md:grid-cols-2 grid mt-4 '>
            <div className='space-y-2'>
             <h3 className='md:font-normal md:text-base text-xs'>Senior Full Stack<br /> Developer</h3>
             <p  className='font-light text-xs md:text-sm'>Smartech PLC</p>
             <p  className='font-light text-xs md:text-sm'>Addis Ababa, Ethiopia</p>
            </div>
            <div>
              <p className='text-justify font-light text-gray-800 text-sm md:text-base md:mt-0 mt-3'>Backend development of various projects using different technologies
              Frontend implementation of various products with different technology stacks
              Database development and optimization of data-heavy operations
              Breaking down and assigning tasks, management</p>
            </div>
          </div>
        </div>
        </div>

        <div className='flex justify-center mt-8'>
        <div className='container border-box md:w-[700px] md:h-[370px] border shadow-lg p-10 rounded-lg bg-[#F5F5F5]'>
          <h1 className='text-blue-600 text-sm md:text-xl md:font-normal'>February 2015 - November 2018</h1>
          <div className='md:grid md:grid-cols-2 grid mt-4 '>
            <div className='space-y-2'>
             <h3 className='md:font-normal md:text-base text-xs'>DevOps Engineer</h3>
             <p  className='font-light text-xs md:text-sm'>Pixel Technologies</p>
             <p  className='font-light text-xs md:text-sm'>Addis Ababa, Ethiopia</p>
            </div>
            <div>
              <p className='text-justify font-light text-gray-800 text-sm md:text-base md:mt-0 mt-3'>Decide deployment approach for different products
              Develop deployment configurations, scripts, backups etc.. for various systems
              Manage and maintain deployed systems performance and reliability</p>
            </div>
          </div>
        </div>
        </div>

    </div>
  )
}

export default Resume

