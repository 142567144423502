import React, {useState} from 'react'
import { RiMenuLine, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.jpg';


const Navbar = () => {

  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)
  const handleClose = () => setNav(!nav) 

  return (
    <div className='w-full fixed bg-white h-[100px] z-10'>
      <div className='px-2  flex justify-between item-center w-full h-full'>
          <div className='flex items-center'> 
               <img src={logo} alt="log" className='w-6 h-6 rounded-md overflow-y-hidden' />
               <div className='fixed md:flex items-center ml-10'>
               <Link to="/" smooth={true} duration={500}>  <h4 className='mr-4 sm:text-2xl -ml-2 font-bold'>Yared Taddese </h4></Link>
                <h4>/ Full Stack Developer</h4>
               </div>
           <ul className='hidden lg:flex fixed w-screen cursor-pointer text-lg justify-center items-center gap-5 ml-[250px] font-medium'>
                <li className='hover:text-blue-700  active:text-blue-600'><Link to="/" smooth={true} duration={500}>About Me</Link></li>
                <li className='hover:text-blue-700  active:text-blue-600'><Link to="/resume" smooth={true} offset={-200} duration={500}>Resume</Link></li>
                <li className='hover:text-blue-700  active:text-blue-600'><Link to="/project" smooth={true} offset={-100} duration={500}>Projects</Link></li>
                <li className='hover:text-blue-700  active:text-blue-600'><Link to="/contact" smooth={true} offset={-50} duration={500}>Contact</Link></li>
          </ul>
          </div>
          <div className='lg:hidden mr-10  mt-10' onClick={handleClick}>
           {!nav ? <RiMenuLine size={27} className='w-5 ' /> : <RiCloseLine size={27} className='w-5' />}
          </div>    
      </div>
       <ul className={!nav ? 'hidden' : 'absolute items-center bg-[#F4ECE6] w-full space-y-2 md:space-y-5 text-base md:text-2xl font-normal rounded-t-3xl text-center px-8'}>
          <li className=' w-full mt-5 md:mt-7'><Link onClick={handleClose} to="/" smooth={true} offset={-200} duration={500}>About Me</Link></li>
          <li className=' w-full'><Link onClick={handleClose} to="/Resume" smooth={true} offset={-50} duration={500}>Resume</Link></li>
          <li className=' w-full'><Link onClick={handleClose} to="/project" smooth={true} offset={-50} duration={500}>Projects</Link></li>
          <li className=' w-full'><Link onClick={handleClose} to="/contact" smooth={true} offset={-50} duration={500}>Contact</Link></li>
       </ul> 
    </div>
  )
}

export default Navbar

