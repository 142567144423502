import React from 'react'
import ad from '../../assets/ad.jpg'
import p1 from '../../assets/p1.png'
import p2 from '../../assets/p2.jpg'
import st from '../../assets/st.png'
function Project() {
  return (
    <div className='p-12 bg-[#E6DACE] overflow-x-hidden'>
      <h1 className='text-center text-lg md:text-xl lg:text-3xl font-bold mt-[100px]'>PROJECTS</h1>
      <div className='flex justify-center mt-3 md:mt-8'>
        <div className='container border-box lg:w-[900px] lg:h-[400px] border shadow-lg p-3 md:p-6 lg:p-10 rounded-lg bg-[#fff]'>
          <h1 className='text-blue-600 text-sm md:text-xl font-normal'>AdSolve Advertising— Golang, <br /> React, PostgreSQL, Gitlab, AWS</h1>
          <div className='lg:grid lg:grid-cols-2 grid mt-4 '>
            <div className='space-y-2'>
            <p className='text-justify font-light text-gray-800 text-xs md:text-lg lg:text-base'>Implemented advertising network management of each advertisement resources, assigning advertisements and scheduling task
            Implemented an approach to match visitors with the best ad on the current network based on different criteria
            I implemented an accounting module to track ad visits and payments of the network.
            Complete design and implementation of admin UI Admin and network database are developed with PostgreSQL
            The system is deployed with AWS. I was responsible for system deployment and maintenance on AWS.</p>
            </div>
            <div>
              <img className='lg:w-[430px] lg:h-[257px] md:w-[700px] mt-4 lg:mt-0 md:h-[320px] h-[200px] w-[300px] rounded-md' src={ad} alt='pic' />
            </div>
          </div>
        </div>
        </div>
      
      <div className='flex justify-center mt-6 md:mt-8'>
        <div className='container border-box lg:w-[900px] lg:h-[450px] border shadow-lg p-3 md:p-6 lg:p-10 rounded-lg bg-[#fff]'>
          <h1 className='text-blue-600 text-sm md:text-xl font-normal'>Online Poker Platform— Python, <br /> Javascript, Node.js, React.js, MySQL, <br /> Docker, Kubernetes, Gitlab, AWS</h1>
          <div className='lg:grid lg:grid-cols-2 grid mt-4 '>
            <div className='space-y-2'>
            <p className='text-justify font-light text-gray-800 text-xs md:text-lg lg:text-base'>A very huge and complex system, that supports 10  different game types of poker
             Implemented UI of the game is very complex with dynamic complex game states and very intricate user interactions
             Very I/O intensive operations implemented into independent services in Node.js(scheduler and most background services)
             The system database is designed and implemented with MySQL.
             Implemented an approach to reduce game play latency, the game state is synced between Redis and MySQL for maximum efficiency and reduced gameplay latency.
             Manage and maintain the deployment of more than 10 services on AWS.</p>
            </div>
            <div>
              <img className='lg:w-[430px] lg:ml-4 lg:h-[257px] md:w-[700px] mt-4 lg:mt-0 md:h-[320px] h-[200px] w-[300px] rounded-md' src={p1} alt='pic' />
            </div>
          </div>
        </div>
        </div>

        <div className='flex justify-center mt-6 md:mt-8'>
        <div className='container border-box lg:w-[900px] lg:h-[450px] border shadow-lg p-3 md:p-6 lg:p-10 rounded-lg bg-[#fff]'>
          <h1 className='text-blue-600 text-sm md:text-xl font-normal'>Online Poker Platform Redesigned— <br />Golang, React.js, MySQL, Docker, <br />Kubernetes, Gitlab, AWS</h1>
          <div className='lg:grid lg:grid-cols-2 mt-4 '>
            <div className='space-y-2'>
            <p className='text-justify font-light text-gray-800 text-xs md:text-lg lg:text-base'>This is a complete rewrite of an already existing project(the project I listed above)
              A very huge and complex system, that supports 10 different game types of poker 
              Redesign legacy codebase and approaches to latest trends and techniques
              A complete rewrite of implementation of legacy services using Go
              Implemented UI of the game is very complex with dynamic complex game states and very intricate user interactions
              The system database is designed and implemented with MySQL.
              Manage and maintain the deployment of more than 5 services written in Golang on AWS.</p>
            </div>
            <div>
              <img className='lg:w-[430px] lg:ml-4 lg:h-[257px] md:w-[700px] mt-4 lg:mt-0 md:h-[320px] h-[200px] w-[300px]  rounded-md' src={p2} alt='pic' />
            </div>
          </div>
        </div>
        </div>

         <div className='flex justify-center mt-6 md:mt-8'>
        <div className='container border-box lg:w-[900px] lg:h-[500px] border shadow-lg p-3 md:p-6 lg:p-10 rounded-lg bg-[#fff]'>
          <h1 className='text-blue-600 text-sm md:text-xl font-normal'>Gebeyet Stock Information System— <br /> Node.js, Vue.js, PostgreSQL, Docker,<br /> Kubernetes, Gitlab, AWS, RedShift</h1>
          <div className='lg:grid lg:grid-cols-2 grid mt-4 '>
            <div className='space-y-2'>
            <p className='text-justify font-light text-gray-800 text-xs md:text-lg lg:text-base'>I have worked on reporting different stock status with complex reporting features and views
            I have worked on designing account management module and integration with other modules(implemented account management module and tested and fixed issues of integration with other system modules)
            I have worked on optimizing system performance.(designing database caching mechanism using redis which enhances data loading performance significantly)
            I worry about code maintainability since I managed the dev team and their implementation for the project. I had set up a strict code quality check-up mechanism.
            I was great at communicating with the development team since I have managed the dev team..</p>
            </div>
            <div>
              <img className='lg:w-[430px] lg:h-[257px] md:w-[700px] mt-4 lg:mt-0 md:h-[320px] h-[200px] w-[300px] rounded-md' src={st} alt='pic' />
            </div>
          </div>
        </div>
        </div>


        </div>
  )
}

export default Project