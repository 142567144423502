import React from "react";
import pic from "../../assets/pic.svg";
import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";
function Aboutme() {
  return (
    <div className="lg:flex gap-[140px] overflow-x-hidden">
      <div className="box-border h-[700px] lg:h-[800px] 2xl:h-[900px] 2xl:w-[800px] lg:w-[512px] bg-[#E6DACE] p-4 border-1">
        <div className="rounded-lg box-border w-[350px] h-[420px] md:w-[375px] md:h-[450px] bg-[#F4ECE6] p-4 border-1 mt-[140px] 2xl:mt-[230px] 2xl:ml-[470px] md:ml-[200px] shadow-2xl">
          <div className="relative  box-border w-[350px] md:w-[375px] h-[53px] bg-[#F5F5F5] p-4 border-1 mt-[400px] -ml-4 shadow-2xl">
            <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <img
                className="-mt-[400px] z-1 rounded-full"
                src={pic}
                alt="pic profile"
              />
              <h1 className="flex justify-center mt-8 text-xl lg:text-2xl font-bold">
                Yared Taddese
              </h1>
              <div className="h-0.5 w-[100px] ml-10 mt-6 bg-[#0050FF] box-border shadow-lg"></div>
              <h2 className="text-lg mt-6 flex justify-center font-light">
                Full Stack Developer
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:p-0 p-4 md:p-8 mt-3 lg:mt-[250px] 2xl:mt-[300px]">
        <h1 className="text-center md:text-left text-xl lg:text-2xl font-normal">
          Here's who I am & what I do
        </h1>
        <div className="flex justify-center items-center md:flex md:justify-start md:items-left gap-8 lg:gap-[45px] mt-3 lg:mt-6">
          <Link to="/resume">
            <button className="lg:w-[125px] w-[100px] h-[37px] text-white hover:text-black lg:h-[40px] hover:bg-transparent border-cyan-900 border-2 sm:text-sm lg:text-base rounded-full p-1 lg:p-2 bg-[#0050FF]">
              RESUME
            </button>
          </Link>
          <Link to="/project">
            <button className="lg:w-[125px] lg:h-[40px] w-[100px] h-[37px] hover:text-white border-black bg-transparent p-1 lg:p-2 border rounded-full lg:text-base hover:bg-[#0050FF]">
              PROJECTS
            </button>
          </Link>
        </div>
        <div className="lg:flex mt-7">
          <ul className="lg:space-y-2 md:text-lg text-sm ">
            <span className="flex ">
              <TiTick className="mr-2" />
              <p>Backend development: Node.js, PHP, Python, Golang</p>
            </span>
            <span className="flex ">
              <TiTick className="mr-2" />
              <p>
                Frontend development: Vue, React, Ant Design, Material-UI,{" "}
                <br /> Tailwind, HTML5, CSS3
              </p>
            </span>
            <span className="flex ">
              <TiTick className="mr-2" />
              <p>Database development: MySQL, PostgreSQL, MongoDB</p>
            </span>
            <span className="flex ">
              <TiTick className="mr-2" />
              <p>Deployment: AWS, Docker, Kubernetes, GCP, Kafka, Firebase</p>
            </span>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Aboutme;
