import React from 'react'
import  { useRef } from 'react';
import emailjs from 'emailjs-com';
function Footer() {
   const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n0qr9wu', 'template_3c8jovq', form.current, 'el9vlrDsH9XhBHoWY')
    e.target.reset()
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className=' lg:p-12 p-5 mt-5 overflow-x-hidden'>
     <div className='lg:flex md:flex-row md:justify-center lg:space-x-[120px]  grid grid-cols-2 gap-2'>
        <div className='md:text-center'>
          <h1 className='lg:text-base text-sm lg:font-normal lg:mb-1'>Call</h1>
          <p className='lg:font-thin text-xs'>+251 939346647</p>
        </div>
         <div className='md:text-center'>
          <article ref={form} onSubmit={sendEmail}>
          <h1 className='lg:text-base lg:font-normal text-sm lg:mb-1'>Email</h1>
          </article>
           <a href="mailto:weldemeskelyared@gmail.com" target="_blank" rel="noreferrer" ><p className='lg:font-thin text-xs'>weldemeskelyared@gmail.com</p></a>
        </div>
         <div className='md:text-center -ml-12 md:ml-0 '>
          <div className='text-center mt-4'>
          <small className='text-xs font-thin'>
             &copy; 2022 Yared Taddese.
           </small>
          </div>   
        </div>
       
     </div> 
    
    </div>
  )
}

export default Footer